<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        Wo finde ich die Zählernummer?
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>
      Die Zählernummer befindet sich in der Regel unter- oder oberhalb des QR- bzw. Barcodes. Im Folgenden finden Sie ein paar Beispiele, die Ihnen dabei helfen können Ihre eigene Zählernummer ausfindig zu machen.
    </p>
    <p>
      <span class="font-bold block mb-1">Analog</span>
      <ng-container *ngIf="energyType === EnergyType.Gas">
        <img src="/assets/svg/faq-meter-gas-analog.svg" class="w-full lg:w-1/3" alt="Zähler - Gas analog">
      </ng-container>
      <ng-container *ngIf="energyType === EnergyType.Electricity">
        <img src="/assets/svg/faq-meter-electricity-analog.svg" class="w-full lg:w-1/3" alt="Zähler - Strom analog">
      </ng-container>
    </p>
    <p>
      <span class="font-bold block mb-1">Digital</span>
      <ng-container *ngIf="energyType === EnergyType.Gas">
        <img src="/assets/svg/faq-meter-gas-digital.svg" class="w-full lg:w-1/3" alt="Zähler - Gas digital">
      </ng-container>
      <ng-container *ngIf="energyType === EnergyType.Electricity">
        <img src="/assets/svg/faq-meter-electricity-digital.svg" class="w-full lg:w-1/3" alt="Zähler - Strom digital">
      </ng-container>
    </p>
    <p>
      <span class="font-bold block mt-8">Wichtig:</span>
      Zählernummer ≠ (ist nicht gleich) Zählerstand. Die Zählernummer ist eine feste Nummer, die dem Zähler zugeordnet ist. Der Zählerstand zeigt hingegen an, wie viel Strom oder Gas Sie bereits verbraucht haben und ändert sich fortlaufend.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        Wie ist die Zählernummer aufgebaut?
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>
      Länge und Aufbau einer Zählernummer kann nach Region und Netzbetreiber variieren. Sie kann eine reine Abfolge von Zahlen oder eine Mischung aus Buchstaben und Zahlen sein.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        Wo finde ich den Zähler?
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>
      Der Stromzähler kann an verschiedenen Stellen im Wohngebäude installiert sein. Häufig befindet er sich in der Nähe der Sicherungen im Keller oder im Hausflur, gelegentlich auch direkt in der Wohnung. Falls Sie Schwierigkeiten haben, Ihren Stromzähler zu finden, können Sie sich an die Hausverwaltung, Ihren Vermieter oder Ihre Vermieterin wenden.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        Was ist die Marktlokations-ID?
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>
      Mit dieser elfstelligen Identifikationsnummer können wir Ihre Verbrauchsstelle präzise identifizieren, was mögliche Zählerverwechslungen ausschließt. Sie können diese Nummer auf Ihrer letzten Rechnung finden oder sie bei Ihrem aktuellen Versorger oder Netzbetreiber anfragen.
    </p>
  </mat-expansion-panel>
</mat-accordion>




