import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeterFaqComponent } from './components/meter-faq.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [MeterFaqComponent],
  exports: [MeterFaqComponent],
  imports: [
    CommonModule,
    MatExpansionModule
  ]
})
export class FaqModule { }
