<div class="container mx-auto h-full flex items-center px-4 md:px-8 lg:px-24">
  <div class="flex flex-col w-full">
    <div class="flex flex-col w-full md:flex-row md:flex-wrap md:gap-x-10 lg:gap-x-24 md:items-center">
      <mat-card class="!p-8 md:!p-10 flex-1 mt-10 md:mt-0 mb-14 md:mb-0" *ngIf="!isCreationProcessSuccessfully">
        <mat-card-title class="!my-3">
          <span>Schneller Login</span>
        </mat-card-title>
        <p>
          Geben Sie bitte Ihre Vertragsnummer ein. Den Link zum Login bekommen Sie dann per E-Mail.
        </p>
        <mat-card-content>
          <form *ngIf="magicKeyCreateFormGroup" #magicKeyCreateForm="ngForm" [formGroup]="magicKeyCreateFormGroup"
                (ngSubmit)="onSubmit()">
            <div class="flex flex-col">
              <mat-form-field class="w-full mt-5" appearance="fill">
                <mat-label>Vertragsnummer</mat-label>
                <input type="text"
                       formControlName="contractId"
                       matInput>
                <mat-error *ngIf="magicKeyCreateFormGroup.controls.contractId.errors?.required">
                  <mat-icon>error</mat-icon>
                  Pflichtfeld
                </mat-error>
                <mat-error *ngIf="magicKeyCreateFormGroup.controls.contractId.errors?.maxlength">
                  <mat-icon>error</mat-icon>
                  Maximal {{ customerOrContractIdMaximumLength }} Stellen
                </mat-error>
                <mat-error *ngIf="isErrorRedirection">
                  <mat-icon>error</mat-icon>
                  Ihr verwendeter Link ist abgelaufen. Hier können Sie einen neuen Link zum ‚Schnellen Login‘ generieren.
                </mat-error>
                <mat-error *ngIf="isCreationProcessFaulty">
                  <mat-icon>error</mat-icon>
                  Der ‚Schnelle Login‘ konnte nicht angefordert werden, bitte prüfen Sie Ihre Eingaben und versuchen es erneut.
                </mat-error>
              </mat-form-field>
              <div class="flex flex-col md:flex-row md:flex-wrap mt-10 items-center justify-between w-full">
                <div class="w-full md:w-auto mb-3 md:mb-0">
                  <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="isCreationProcessOngoing"></app-button>
                </div>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="isCreationProcessSuccessfully">
        <mat-card class="!p-8 lg:!p-10 flex-1 mt-10 md:mt-0 mb-14 lg:mb-0">
          <mat-icon class="icon-post"> mail_outline</mat-icon>
          <mat-card-title class="mb-4 mat-title">
            <span>E-Mail Postfach prüfen</span>
          </mat-card-title>
          <mat-card-content>
            <p>Wir haben Ihren Link per E-Mail an <span class="mat-body-2">{{ usedEmailAddress }}</span> versendet.</p>
            <p>Bitte auch den Spam-Ordner prüfen.</p>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <div class="flex-1 flex flex-col mb-5 md:mb-0 px-8 md:px-0">
        <mat-card-title class="primaryColor !mb-4">So einfach gehts</mat-card-title>
        <ul class="flex flex-col mat-body-2">
          <li class="flex items-center my-1"><span>1</span>Vertragsnummer eingeben</li>
          <li class="flex items-center my-1"><span>2</span>Link per E-Mail erhalten</li>
          <li class="flex items-center my-1"><span>3</span>Link in E-Mail klicken</li>
        </ul>
      </div>
    </div>
    <ng-container *ngIf="environment.showDayaheadPriceOverviewInLogin">
      <app-dayahead-base-prices-overview></app-dayahead-base-prices-overview>
    </ng-container>  
  </div>
</div>
