<div [class]="class">
  <div [ngClass]="{'information': notificationType === NotificationType.Information, 'success': notificationType === NotificationType.Success, 'warning': notificationType === NotificationType.Warning, 'error': notificationType === NotificationType.Error}">
    <p class="p-4 !m-0 text-center">
      <ng-container  *ngIf="notificationType === NotificationType.Error" >
        <mat-icon class="material-symbols-outlined !text-[14px] !w-[16px] !h-[16px]">error</mat-icon>
      </ng-container>
      {{notification}}
    </p>
  </div>
</div>
