import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { combineLatest, map, Subject, takeUntil, timer } from 'rxjs';
import { MagicKeyService } from '../services/magic-key.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { fadeAnimation } from '../../shared/fade-animation/fade-animation';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-magic-key-create',
  templateUrl: './magic-key-create.component.html',
  animations: [fadeAnimation]
})
export class MagicKeyCreateComponent implements OnInit, OnDestroy {

  private destroyed = new Subject<void>();
  magicKeyCreateFormGroup: FormGroup | null = null;
  @ViewChild('magicKeyCreateForm') magicKeyCreateForm: NgForm | null = null;
  isCreationProcessOngoing: boolean = false;
  private minimumProgressTimeInMilliseconds: number = 800;
  isErrorRedirection: boolean = false;
  isCreationProcessFaulty: boolean = false;
  isCreationProcessSuccessfully: boolean = false;
  NotificationType = NotificationType;
  customerOrContractIdMaximumLength: number = 20;
  usedEmailAddress: string | null = null;
  @HostBinding('class') flexClass = 'flex-1';
  environment = environment;

  private resetNotifications(): void {
    this.isErrorRedirection = false;
    this.isCreationProcessFaulty = false;
    this.isCreationProcessSuccessfully = false;
  }

  constructor(private route: ActivatedRoute, private magicKeyService: MagicKeyService, private formBuilder: FormBuilder) {
  }

  onSubmit(): void {
    if (this.magicKeyCreateForm!.invalid || this.isCreationProcessOngoing) {
      return;
    }
    this.resetNotifications();
    this.isCreationProcessOngoing = true;
    let magicKeyCreateSubscription = this.magicKeyService.create(this.magicKeyCreateFormGroup!.controls.contractId.value);
    combineLatest([timer(this.minimumProgressTimeInMilliseconds), magicKeyCreateSubscription])
      .pipe(takeUntil(this.destroyed))
      .pipe(map(observables => observables[1])).subscribe(observable => {
        if (observable) {
          this.magicKeyCreateForm!.resetForm();
          this.isCreationProcessSuccessfully = true;
          this.usedEmailAddress = observable.emailAddress
        }
        else {
          this.isCreationProcessFaulty = true;
        }
      }).add(() => {
        this.isCreationProcessOngoing = false;
      });
  }

  ngOnInit(): void {
    let isErrorRedirection = this.route.snapshot.queryParamMap.get('isErrorRedirection');
    if (isErrorRedirection) {
      this.isErrorRedirection = JSON.parse(isErrorRedirection);
    }
    this.magicKeyCreateFormGroup = this.formBuilder.group({
      contractId: [null, [Validators.required, Validators.maxLength(this.customerOrContractIdMaximumLength)]]
    });
    this.magicKeyCreateFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.resetNotifications();
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

}

