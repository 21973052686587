<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Zahlbetrag ändern
    </h1>
  </mat-card-title>
  <ng-container *ngIf="detail.isInValueState && monthlyPaymentFormGroup && detail.value">
    <ng-container *ngIf="!detail.value.nextPayment.paymentDate">
      <app-notification [notificationType]="NotificationType.Information"
                        notification="Eine Anpassung des Zahlbetrags ist nur während der Belieferung möglich.">
      </app-notification>
    </ng-container>
    <ng-container *ngIf="detail.value.nextPayment.paymentDate">
      <div class="flex flex-col md:flex-row gap-x-5">
        <div class="flex flex-1 justify-between">
          <h1 class="!mb-0 mat-subheading-2">Monatlich in €</h1>
          <span>ab {{ detail.value.nextPayment.paymentDate|date:'mediumDate' }}</span>
        </div>
        <div class="flex-1"></div>
      </div>
      <form (ngSubmit)="save()" #monthlyPaymentForm="ngForm" [formGroup]="monthlyPaymentFormGroup">
        <div class="flex flex-col md:flex-row gap-x-5">
          <div class="flex flex-1 justify-between" *ngIf="monthlyPaymentFormGroup && detail">
            <mat-form-field class="w-full" appearance="fill" style="position: relative;">
              <span matPrefix
                    style="width: 25px; height: 25px; background-color: #707070; position: absolute; top: -26px; left: 5px; border-radius: 100%; color: white; display: flex; justify-content: center; align-items: center; font-size: 20px; cursor: pointer; z-index: 10;"
                    (click)="decrementMonthlyPaymentEuroGross()">-</span>
              <input matInput type="number" formControlName="selectedMonthlyPaymentEuroGross" style="text-align: center;">
              <span matSuffix
                    style="width: 25px; height: 25px; background-color: #707070; position: absolute; top: -26px; right: 5px; border-radius: 100%; color: white; display: flex; justify-content: center; align-items: center; font-size: 20px; cursor: pointer; z-index: 10;"
                    (click)="incrementMonthlyPaymentEuroGross()">+</span>
              <mat-error>
                Bitte wählen Sie einen Betrag zwischen {{ lowerLimitEuroGross | number: '1.0-0' }}€
                und {{ upperLimitEuroGross| number: '1.0-0' }}€
              </mat-error>
              <mat-hint *ngIf="priceCapInEuroGross">
                Kostenairbag {{ priceCapInEuroGross | number: '1.0-0' }}€
                ({{ priceCapInKwh | number: '1.0-0' }}kwh) *
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="flex-1 mt-5 md:mt-0">
            <div class="content border border-[#e4e4e4] rounded px-5 py-[18px]" style="">
              Der Zahlbetrag entspricht einem Jahresverbrauch
              von {{ monthlyPaymentFormGroup.controls.monthlyPaymentKwh.value }} kWh.
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-x-5 mt-10">
          <div class="flex flex-1 flex-wrap justify-between">
            <div class="flex-1 flex flex-wrap">
              <span class="mat-subheading-2 !my-0 w-full">Ihre nächste Zahlung</span>
              <span *ngIf="detail.value.nextPayment.paymentMethod === PaymentMethod.DirectDebit" class="text-[#cfcfcf]">
                wird abgebucht am {{ detail.value.nextPayment.paymentDate|date:'mediumDate' }}
              </span>
              <span *ngIf="detail.value.nextPayment.paymentMethod === PaymentMethod.BankTransfer" class="text-[#cfcfcf]">
                fällig bis zum {{ detail.value.nextPayment.paymentDate|date:'mediumDate' }}
              </span>
            </div>
            <span class="mat-headline">
              {{ detail.value.nextPayment.currentMonthlyPayment|number : '1.2' }} €
            </span>
          </div>
          <div class="flex flex-col md:flex-row flex-1 justify-end mt-5 md:mt-0">
            <div class="w-auto">
              <app-button caption="Absenden" color="primary" type="submit"
                          [progressBarVisible]="monthlyPaymentSaveOperation.isInLoadingState"></app-button>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-x-5 mt-5" *ngIf="priceCapInEuroGross">
          <div class="flex flex-1">
            <span class="text-[#cfcfcf] text-[12px] leading-4">
              * Kostenairbag: Die per Stand 27.12.2023 in der nächsten Jahresverbrauchsabrechnung nicht fällige Differenz zwischen Ihrem obigen monatlichen Zahlbetrag und dem rechnerischen Abschlag gemäß Jahresverbrauchsprognose.
            </span>
          </div>
          <div class="flex flex-1"></div>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="detail.isInLoadingState">
    <div class="flex flex-col items-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="detail.isInErrorState">
    <app-notification [notificationType]="NotificationType.Error"
                      notification="Ihr Zahlbetrag kann zurzeit nicht angepasst werden – Bitte versuchen Sie es später erneut.">
    </app-notification>
  </ng-container>
  <ng-container *ngIf="detail.isInNoValueState">
    <app-notification [notificationType]="NotificationType.Warning"
                      notification="Bisher wurden noch keine Daten hinterlegt.">
    </app-notification>
  </ng-container>
</div>
