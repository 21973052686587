import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import { FooterModule } from './modules/footer/footer.module';
import { HeaderModule } from './modules/header/header.module';
import { MatCardModule } from '@angular/material/card';
import { MoveModule } from './modules/move/move.module';
import { MeterReadingModule } from "./modules/meter-reading/meter-reading.module";
import { MonthlyPaymentModule } from "./modules/monthly-payment/monthly-payment.module";
import { LoginModule } from './modules/login/login.module';
import { AuthGuardService } from './modules/shared/services/auth-guard.service';
import { AntiAuthGuardService } from './modules/shared/services/anti-auth-guard.service';
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { GermanDateAdapter } from "./german-date-adapter";
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { environment } from '../environments/environment';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { ContactModule } from "./modules/contact/contact.module";
import { PaymentInformationModule } from './modules/payment-information/payment-information.module';
import { ContractModule } from './modules/contract-overview/contract.module';
import { DocumentsModule } from "./modules/documents/documents.module";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { GermanMatPaginator } from "./modules/shared/components/german-mat-paginator";
import { CreditSettlementModule } from './modules/credit-settlement/credit-settlement.module';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { OrderCorrectionModule } from "./modules/order-correction/order-correction.module";
import { LegalLinksModule } from "./modules/legal-links/legal-links.module";
import { TariffModule } from './modules/tariff/tariff.module';
import moment from 'moment';
import {AccountStatementModule} from "./modules/account-statement/account-statement.module";
registerLocaleData(localeDe);
moment.locale('de');

export const GERMAN_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    DashboardModule,
    ContractModule,
    LoginModule,
    MoveModule,
    PaymentInformationModule,
    MeterReadingModule,
    MonthlyPaymentModule,
    ContactModule,
    OrderCorrectionModule,
    TariffModule,
    MatSnackBarModule,
    MatCardModule,
    HeaderModule,
    FooterModule,
    ConfirmDialogModule,
    DocumentsModule,
    CreditSettlementModule,
    RouterModule.forRoot([{ path: '**', redirectTo: environment.loginUrl, pathMatch: 'full' }], { scrollPositionRestoration: 'top' }),
    LegalLinksModule,
    AccountStatementModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GERMAN_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: GermanDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, verticalPosition: 'top', horizontalPosition: 'end' } },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MatPaginatorIntl, useClass: GermanMatPaginator },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    AuthGuardService,
    AntiAuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
