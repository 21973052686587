import { ObservableDisplayState } from "../enumerations/observable-display-state";

export class ObservableStatus<T> {

  error: Error | null;
  value: T | null;
  isRunning: boolean;
  displayState: ObservableDisplayState;

  constructor(error: Error | null, value: T | null, isRunning: boolean, displayState: ObservableDisplayState) {
    this.error = error;
    this.value = value;
    this.isRunning = isRunning;
    this.displayState = displayState;
  }

  get isInLoadingState(): boolean {
    return this.displayState === ObservableDisplayState.Loading;
  }

  get isInNoneState(): boolean {
    return this.displayState === ObservableDisplayState.None;
  }

  get isInValueState(): boolean {
    return this.displayState === ObservableDisplayState.Value;
  }

  get isInNoValueState(): boolean {
    return this.displayState === ObservableDisplayState.NoValue;
  }

  get isInErrorState(): boolean {
    return this.displayState === ObservableDisplayState.Error;
  }

  static noValue<T>(): ObservableStatus<T> {
    return new ObservableStatus<T>(null, null, false, ObservableDisplayState.NoValue);
  }

  static none<T>(): ObservableStatus<T> {
    return new ObservableStatus<T>(null, null, false, ObservableDisplayState.None);
  }

}
